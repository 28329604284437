/* login.css */
.login-container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background: black;
    padding: 20px; 
}


/* Left Logo Section */
.ImageLogo {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    padding: 20px;
}

.ImageLogo img {
    width: 60%;
    max-width: 450px;
    height: auto;
}

/* Right Login Section */
.login-container > div:last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: black;
    padding: 40px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

/* Form Styling */
form {
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

label {
    font-size: 14px;
    font-weight: bold;
    color: #444;
}

input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
}

input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Login Button */
.buttonLogin {
    width: 107%;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.buttonLogin:hover {
    background: #0056b3;
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
    }

    .ImageLogo {
        display: none; /* Hide logo on mobile */
    }

    .login-container > div:last-child {
        width: 100%;
        height: 100vh;
        box-shadow: none;
        border-radius: 0;
    }
}
