/* General form container */
.form-container {
  width: 60%;
  margin: 20px auto;
  padding: 20px;
  background: #222;
  color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  top: 20px; 
  bottom: 20px;
}

/* Form styling */
.form {
  display: flex;
  flex-direction: column;
}

/* Row with two equal fields */
.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

/* Label and input styling */
.form-group {
  flex: 1; /* Makes both fields equal width */
  display: flex;
  flex-direction: column;
}

label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #ddd;
}

input,
select {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #333;
  color: white;
  outline: none;
  width: 90%;
}

input:focus,
select:focus {
  border-color: #007bff;
}

/* Submit button */
.submit-btn {
  background: #007bff;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 50%;
  margin: 20px auto;
  top: 20px
}

.submit-btn:hover {
  background: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}
