/* General Styling */
body {
    font-family: Arial, sans-serif;
    background-color: #121212;
    color: #ffffff;
    margin: 0;
    padding: 0;
}

/* Form Container */
.form-container22 {
    max-width: 100%;
    margin: 40px auto;
    padding: 20px;
    background: #1e1e1e;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
    text-align: center;
}

/* Heading */
h2 {
    color: #ffffff;
    font-size: 24px;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-left: 20px; /* Moves the heading 20px from the left */
    text-align: left; /* Ensures text is aligned to the left */
}

/* Filters Section */
.filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.filter_data_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.filters select,
.filters input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #333;
    background: #2a2a2a;
    color: #ffffff;
    font-size: 16px;
    outline: none;
}

.filters input::placeholder {
    color: #aaaaaa;
}

.filters select:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Buttons */
.submit-btn {
    padding: 10px 15px;
    background: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
}

.submit-btn:hover {
    background: #0056b3;
}

.clear-btn {
    background: #ff5733;
}

.clear-btn:hover {
    background: #d9462e;
}

/* Table Container (Prevents Overflow) */
.table-container {
    overflow-x: auto;
    width: 100%;
    max-width: calc(100% + 50px); /* Increase width by 50px */
}

/* Table Styling */
.license-table {
    width: calc(100% + 50px);
    max-width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 50px;
    margin-right: 20px;
    margin-left: 20px;
    background: #262626;
    color: white;
}

.license-table th,
.license-table td {
    padding: 12px;
    border: 1px solid #444;
    text-align: center;
}

.license-table th {
    background: #333;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 14px;
}

.license-table tbody tr:nth-child(odd) {
    background: #252525;
}

.license-table tbody tr:nth-child(even) {
    background: #1c1c1c;
}

.license-table tbody tr:hover {
    background: #548bce;
}

/* Status Styling */
.status-active {
    color: #4caf50;
    font-weight: bold;
}

.status-expired {
    color: #e74c3c;
    font-weight: bold;
}

/* No Data Message */
.no-data {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: #aaaaaa;
}

/* Delete Icon */
.delete-icon {
    color: #e74c3c;
    cursor: pointer;
    transition: 0.3s;
}

.delete-icon:hover {
    color: #ff0000;
    transform: scale(1.2);
}

/* Responsive Design */
@media (max-width: 768px) {
    .filters {
        flex-direction: column;
        align-items: center;
    }

    .filters select,
    .filters input {
        width: 100%;
        max-width: 300px;
    }

    .license-table {
        font-size: 14px;
    }

    .license-table th,
    .license-table td {
        padding: 8px;
    }
}
