.form-container {
    max-width: 600px;
    margin: auto; /* Centers horizontally */
    padding: 30px;
    background: var(--bg-secondary);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
    font-family: 'Poppins', sans-serif;
    color: var(--text-primary);

    display: flex;  /* Enables Flexbox */
    flex-direction: column;
    align-items: center; /* Centers content horizontally */
    justify-content: center; /* Centers content vertically */
    min-height: 100vh; /* Makes sure it takes full height */
}

/* Form Layout */
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px; /* More gap between fields */
}

/* Form rows: Fix spacing issue */
.form-row {
    display: flex;
    flex-wrap: wrap; /* Prevents overflow */
    gap: 20px;
    justify-content: space-between;
}

/* Form Groups: Proper spacing */
.form-group {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 48%; /* Prevents shrinking */
}

/* Labels */
label {
    font-weight: 500;
    color: var(--text-primary);
    font-size: 15px;
}

/* Input Fields */
input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background: var(--bg-tertiary);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    color: var(--text-primary);
    outline: none;
    transition: 0.3s ease-in-out;
}

/* Prevent Date Inputs from Shrinking */
input[type="date"] {
    min-width: 150px;
}

/* Responsive: Single column on small screens */
@media (max-width: 600px) {
    .form-row {
        flex-direction: column;
        gap: 10px;
    }
    
    .form-group {
        min-width: 100%;
    }
}
