body{
  font-family: Inter, sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
:root {
  --bg-primary: #121212;
  --bg-secondary: #1E1E1E;
  --bg-tertiary: #252525;
  --bg-quaternary: #2D2D2D;

  --text-primary: #EDEDED;
  --text-secondary: #B0B0B0;
  --text-tertiary: #888888;

  --accent-primary: #757575;
  --accent-secondary: #3b57e3;
  /* --accent-secondary: #57C78A; */
  --accent-tertiary: #E57373;
  --accent-quaternary: #A970FF;

  --danger-red: #c82333;
}

body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

button {
  background-color: var(--accent-primary);
  color: var(--text-primary);
  border-radius: 6px;
  padding: 10px 15px;
}

button:hover {
  background-color: var(--accent-quaternary);
}

/* Scrollbar styles */
::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1); /* Slightly visible thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Invisible track */
}

/* Optional: hide scrollbar when it's not being hovered */
::-webkit-scrollbar:hover {
  width: 12px; /* Make it slightly wider on hover */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.3); /* Make thumb more visible when hovered */
}
