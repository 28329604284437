/* Top Bar */
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-primary);
  padding: 10px 20px;
  color: var(--text-primary);
  position: relative;
}

/* Logo */
.logo-container {
  display: flex;
  align-items: center;
  gap: 30px;
}

.logo-img {
  width: 70px;
  margin-right: 10px;
}

.eams-title {
  font-size: 25px;
  font-weight: 600;
}

.eams-title22 {
  font-size: 10px;
  font-weight: 600;
}

/* Search Bar */
.search-bar-container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.search-bar {
  display: flex;
  align-items: center;
  background: rgba(109, 109, 109, 0.8); /* Glassmorphism Effect */
  padding: 5px 15px;
  border-radius: 25px;
  gap: 10px;
  width: 40%;
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(248, 247, 247, 0.25); /* Subtle shadow */
  transition: all 0.3s ease-in-out;
  position: relative; /* For glowing effect */
}

.search-icon {
  color: var(--text-secondary);
  font-size: 1.2rem;
  transition: all 0.2s ease;
}

.search-icon:hover {
  color: var(--accent-primary);
  transform: scale(1.2); /* Icon scales up slightly */
}

/* Search Input */
.search-input {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  color: var(--text-primary);
  font-size: 1rem;
  font-family: inherit;
  padding: 5px 0;
  transition: color 0.3s ease;
}

.search-input:focus::placeholder {
  opacity: 0.5; /* Placeholder fades on focus */
}

.search-input::placeholder {
  color: var(--text-secondary);
  opacity: 0.8;
}

/* Glowing Effect on Focus */
.search-bar:focus-within {
  box-shadow: 0 0 15px 5px rgba(92, 92, 92, 0.5); /* Glow effect */
}

.eams-text {
  display: flex;
  flex-direction: column;
}

/* Notification and Profile Icons */
/* Icon Container */
.icon-container {
  display: flex;
  gap: 20px;
}

/* Top Bar Icons */
.top-bar-icon {
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for all properties */
}

/* Hover Effects for Icons */
.top-bar-icon:hover {
  color: var(--accent-primary); /* Change icon color */
  transform: scale(1.2); /* Slightly increase size */
  box-shadow: 0 4px 12px rgba(0, 180, 216, 0.3); /* Shadow effect */
}

/* Pulse Effect for Notification Icon (for active notifications) */
.top-bar-icon.notification-icon:hover {
  animation: pulse 1.5s infinite; /* Adds pulse effect when hovered */
}

/* Keyframes for Pulse Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Add active class or specific class for notification icon */
.top-bar-icon.notification-icon {
  color: var(--accent-tertiary); /* Red color for notifications */
}

.top-bar-icon.profile-icon {
  color: var(--accent-quaternary); /* Yellow color for profile */
}

/* Active state for Notification Icon (when new notifications arrive) */
.top-bar-icon.notification-icon.active {
  color: var(--accent-tertiary);
  animation: pulse 1.5s infinite;
}
/* Profile Dropdown */
.ProfileDropDown {
  position: absolute;
  top: 60px;  /* Adjust based on navbar height */
  right: 10px;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 12px;
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
}

/* Show dropdown when profileShow is true */
.ProfileDropDown.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* User Info */
.ProfileDropDown p {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  color: black;
}

/* Logout Button */
.ProfileDropDown button {
  background: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.2s ease-in-out;
  width: 90%;
}

.ProfileDropDown button:hover {
  background: #e60000;
}
