/* Top Navbar Styling */
.top-navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(135deg, var(--bg-tertiary), var(--bg-primary)); /* Gradient background */
  padding: 15px 25px;
  color: var(--text-primary);
  height: 20px;
  gap: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow for depth */
  transition: background 0.3s ease; 
}

/* Navbar Item Styling */
.nav-item {
  display: flex;
  align-items: center;
  justify-content: center; /* Centers content horizontally */
  gap: 10px;
  cursor: pointer;
  padding: 10px 16px; /* Adjusted for better spacing */
  border-radius: 5px;
  transition: all 0.3s ease;
  font-size: 14px; /* Set a readable font size */
  line-height: 1.5; /* Ensures text fills space properly */
  width: 100%; /* Ensures it covers full line space */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Improved font */
  position: relative;
}

/* Hover Effect */
.nav-item:hover {
  background-color: var(--bg-tertiary);
  transform: scale(1.05); /* Slightly grow the item */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Add hover shadow */
}

/* Active Tab Styling */
.nav-item.active {
  background-color: var(--accent-secondary); /* Highlight active tab */
  color: var(--text-primary);
  transform: scale(1.1); /* Slightly scale up active tab */
  box-shadow: 0 4px 12px rgba(139, 139, 139, 0.4); /* Active item shadow */
}

/* Active Label Styling */
.nav-item.active .nav-label {
  font-weight: 500; /* Make the label bold when active */
  color: var(--text-primary); /* Active label color */

}

/* Icon Styling */
.nav-item svg {
  font-size: 1rem; /* Larger icons for better visibility */
  transition: all 0.3s ease;
}

/* Hover Effect on Icon */
.nav-item:hover svg {
  transform: rotate(10deg); /* Slight rotation on hover */
  color: var(--text-primary); /* Yellow color for hover */
}
.nav-item.active svg {
  transform: rotate(10deg); /* Slight rotation on hover */
  color: var(--text-primary); /* Yellow color for hover */
}

/* Label Styling */
.nav-label {
  font-size: 12px; /* Larger text for better readability */
  font-weight: 500;
  transition: color 0.3s ease;
  color: var(--text-secondary); /* Default color for label */
  letter-spacing: 1px; /* Slight letter-spacing for modern look */
  text-transform: capitalize; /* Capitalize first letter of each word */
}

/* Hover effect on text */
.nav-item:hover .nav-label {
  color: var(--text-primaryz); /* Change label color to yellow on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
  .top-navbar {
    gap: 20px; /* Reduce space between items on smaller screens */
  }

  .nav-item {
    font-size: 0.9rem; /* Slightly smaller text on smaller screens */
    padding: 6px 12px;
  }

  .nav-item svg {
    font-size: 1.4rem; /* Slightly smaller icons on smaller screens */
  }
}
