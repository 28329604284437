/* Sidebar Container */
.sidebar {
  position: absolute;
  top: 0; 
  left: 0;
  width: 240px;
  height: 100vh;
  margin-top: 170px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  padding: 20px;
  z-index: 1000;
  border-right: 1px solid var(--bg-tertiary);
  overflow-y: auto; 
  transition: left 1s ease-in-out; /* Smooth sliding */
}


.sidebar-toggle {
  transform: translateY(-50%);
  background-color: #252526;
  color: #fff;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #444;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.sidebar-toggle:hover {
  background-color: #333;
}

/* Sidebar Open */
.sidebar.open {
  left: 0; /* Slide into view */
}

/* Sidebar Menu */
.sidebar-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Sidebar Items */
.sidebar-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 14px 18px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover & Active Effects */
.sidebar-item:hover {
  background-color: var(--bg-hover);
  transform: scale(1.05);
}

.sidebar-item.active {
  background-color: var(--bg-active);
  font-weight: 600;
}

/* Sidebar Toggle Button (Closed) */
.sidebar-toggle-closed {
  position: fixed;
  top: 50%;
  left: 15px; /* Visible edge */
  transform: translateY(-50%);
  background-color: var(--bg-primary);
  color: var(--text-primary);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

/* Hover Effect */
.sidebar-toggle-closed:hover {
  background-color: var(--bg-hover);
  transform: scale(1.1);
}

/* Submenu */
.submenu {
  list-style: none;
  padding-left: 20px;
  margin-top: 10px;
}

.submenu-item {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 3px;
  transition: background-color 0.3s;
}

.submenu-item:hover {
  background-color: var(--bg-hover);
}

/* Voice Button (Always on top) */
.voice-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.voice-button:hover {
  transform: scale(1.1);
}

.voice-button:active {
  transform: scale(0.95);
}

/* Popup Menu */
.voice-popup {
  position: fixed;
  bottom: 100px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  z-index: 9998;
  width: 170px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: all 0.3s ease-in-out;
}

/* Menu Buttons */
.menu-item {
  background-color: black;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-item:hover {
  background-color: gray;
}
/* Disabled Sidebar Items */
.sidebar-item.disabled,
.submenu-item.disabled {
  cursor: not-allowed;
  opacity: 0.5; /* Make it visually distinct */
  pointer-events: none; /* Prevents clicking */
}

/* Remove hover effect for disabled items */
.sidebar-item.disabled:hover,
.submenu-item.disabled:hover {
  background-color: transparent;
  transform: none;
}
