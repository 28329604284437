.chatbot-container {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
}

.chatbot-container.open .chatbot-window {
    transform: translateY(0);
    opacity: 1;
  }

.chatbot-toggle {
  background: #1a73e8;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
}

.chatbot-window {
  background: #2d2d2d;
  width: 350px;
  height: 500px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  transform: translateY(20px);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 10px 30px rgba(0,0,0,0.3);
}

.chat-messages {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;
}

.chat-messages::-webkit-scrollbar {
    width: 8px;
  }
  
  .chat-messages::-webkit-scrollbar-track {
    background: #2d2d2d;
  }
  
  .chat-messages::-webkit-scrollbar-thumb {
    background: #4a4a4a;
    border-radius: 4px;
  }

.message {
  margin: 0.5rem 0;
  padding: 0.8rem;
  border-radius: 15px;
  max-width: 80%;
  word-break: break-word;
  white-space: pre-wrap;
  margin: 10px 0;
}

.message.bot {
  background: #3d3d3d;
  color: white;
  align-self: flex-start;
}

.message.user {
  background: #1a73e8;
  color: white;
  align-self: flex-end;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
  max-height: 200px;
  overflow-y: auto;
}

.options button {
  background: #4a4a4a;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
  transition: background 0.2s ease;
}

.option-button:hover {
    background: #5a5a5a !important;
  }

.chat-input {
  display: flex;
  padding: 1rem;
  gap: 0.5rem;
}

.chat-input input {
  flex: 1;
  padding: 0.8rem;
  border: none;
  border-radius: 8px;
  background: #3d3d3d;
  color: white;
}

.chat-input button {
  background: #1a73e8;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
}