.amf-container {
  max-width: 800px;
  margin-left: 300px;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 20px;
  border: 1px solid var(--accent-primary);
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--bg-quaternary);
}

.amf-title {
  text-align: center;
  font-size: 1.5rem;
  color: var(--text-primary);
  margin-bottom: 20px;
}

.amf-textarea-container {
  margin-top: 15px;
  margin-bottom: 100px;
}

.amf-buttons-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.amf-buttons-container button {
  padding: 10px 15px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.amf-buttons-container button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.amf-buttons-container button:nth-child(1) {
  background-color: var(--accent-secondary);
  color: var(--text-primary);
}

.amf-buttons-container button:nth-child(1):hover {
  background-color: #0056b3; /* Adjust hover if you want it to match the accent */
}

.amf-buttons-container button:nth-child(2) {
  background-color: var(--accent-quaternary);
  color: var(--text-primary);
}

.amf-buttons-container button:nth-child(2):hover {
  background-color: #1e7e34; /* Adjust hover if you want it to match the accent */
}

.amf-buttons-container button:nth-child(3) {
  background-color: var(--accent-tertiary);
  color: var(--text-primary);
}

.amf-buttons-container button:nth-child(3):hover {
  background-color: var(
    --danger-red
  ); /* Adjust hover if you want it to match the accent */
}

.custom-input {
    display: flex; /* Make the div a flex container */
    justify-content: flex-end; /* Align child content to the right */
    width: 100%; /* Ensure it takes the full width of the parent */
  }
  

.tabs-container {
  display: flex;
  border-bottom: 2px solid var(--accent-primary);
  margin-bottom: 20px;
}

.tabs-container div {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--text-secondary);
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.tabs-container div:hover {
  color: var(--text-primary);
}

.tabs-container .active {
  font-weight: bold;
  color: var(--text-primary);
  border-bottom: 3px solid var(--accent-primary);
}

/* Container */
/* .config-form-container {
  width: 320px;
  padding: 20px;
  background-color: var(--bg-primary); 
  box-shadow: none; 
} */

/* Header */
.config-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: var(--text-primary); /* Set text color */
}

/* Plus Icon */
.config-form-add-icon {
  cursor: pointer;
  font-size: 18px;
  color: var(--accent-secondary); /* Accent color */
  transition: transform 0.2s ease-in-out;
}

.config-form-add-icon:hover {
  transform: scale(1.2);
  color: var(--accent-quaternary); /* Change on hover */
}

/* Input Row */
.config-form-input-row {
  display: flex;
  align-items: center;
  margin-left: 100px;
  gap: 10px;
  max-width: 600px;
  margin-bottom: 10px;
  color: var(--text-primary); /* Set text color */
}
.config-form-delete-icon:hover{
    cursor: pointer;
}

/* Input Field */
.config-form-input {
  flex: 1;
  padding: 6px;
  border-radius: 3px;
  border: 1px solid var(--bg-tertiary); /* Use a dark background color for input borders */
  background-color: var(--bg-secondary); /* Input background color */
  color: var(--text-primary); /* Input text color */
  font-size: 14px;
}

/* Checkbox */
.config-form-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
  border: 1px solid var(--bg-tertiary); /* Border color for checkbox */
}

/* Buttons */
.config-form-button-container {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-top: 15px;
}

.config-form-button {
  padding: 8px 15px;
  width: auto;
  background-color: var(--accent-secondary); /* Primary button color */
  color: var(--text-primary); /* Button text color */
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.config-form-button:hover {
  background-color: var(--accent-quaternary); /* Change button color on hover */
}
.config-list-container{
    display: flex;
    gap: 5rem;
    justify-content: space-evenly;
}
.config-list{
max-width: 600px;
}
