  /* IDE CONTAINER */
  .ide-container {
    display: flex;
    flex-direction: column;
    background-color: #1e1e1e;
    color: #d4d4d4;
    border: 1px solid #333;
    border-radius: 5px;
    font-family: "Courier New", Courier, monospace;
    height: 100%;
    max-height: 800px; /* Adjust as needed */
    position: relative;
    overflow: hidden;
  }

  /* Toggle Editing */
  .toggle-edit {
    padding: 8px;
    background-color: #252526;
    color: #d4d4d4;
    border-bottom: 1px solid #333;
    text-align: right;
  }

  .toggle-edit input {
    margin-right: 5px;
  }

  /* EDITOR CONTAINER */
  .editor-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-bottom: 2px solid #333;
    overflow: hidden;
  }

  /* Wrapper for Line Numbers + Text Editor */
  .editor-wrapper {
    display: flex;
    flex-grow: 1;
    height: 100%;
    align-items: stretch;
  }

  /* Line Numbers */
  .line-numbers {
    background-color: #252526;
    color: #858585;
    padding: 10px 8px;
    text-align: right;
    user-select: none;
    overflow: hidden;
    min-width: 50px;
    font-size: 14px;
    line-height: 1.5em;
    border-right: 1px solid #444;
  }

  .line-number {
    font-size: 14px;
  }

  /* TEXT EDITOR */
  .text-editor {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  /* Textarea for Editing */
  .ide-textarea {
    width: 100%;
    height: 100%;
    background-color: #1e1e1e;
    color: #d4d4d4;
    border: none;
    outline: none;
    padding: 10px;
    resize: none;
    font-size: 14px;
    line-height: 1.5em;
    caret-color: #0a84ff;
    white-space: pre;
    overflow: auto;
    font-family: "Courier New", monospace;
  }

  .ide-textarea::placeholder {
    color: #858585;
  }

  /* Syntax Highlighting (Read-Only Mode) */
  .syntax-highlighter {
    background-color: #1e1e1e !important;
    padding: 10px;
    font-size: 14px;
    line-height: 1.5em;
    white-space: pre-wrap;
    word-wrap: break-word;
    border-radius: 3px;
    overflow: auto;
  }

  /* ERROR MESSAGE */
  .error-message {
    color: #ff5555;
    background-color: #300;
    padding: 5px;
    border-radius: 3px;
    font-family: monospace;
    font-size: 12px;
    margin-top: 5px;
  }

/* TERMINAL STYLES */
.terminal {
  background-color: #1e1e1e;
  color: #d4d4d4;
  font-family: monospace;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #333;
  width: 100%;
  height: 120px;
  position: absolute;
  bottom: 0;
  left: 0;
}

  .terminal-header {
    padding: 8px;
    background-color: #252526;
    color: #d4d4d4;
    font-weight: bold;
    text-align: left;
    border-bottom: 1px solid #333;
  }

  .terminal-body {
    padding: 10px;
    height: 100px;
    overflow-y: auto;
    background-color: #000;
    color: #0f0;
    font-size: 14px;
  }

  /* Smooth Scrollbars */
  .text-editor::-webkit-scrollbar,
  .syntax-highlighter::-webkit-scrollbar {
    width: 8px;
  }

  .text-editor::-webkit-scrollbar-thumb,
  .syntax-highlighter::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 4px;
  }

  .text-editor::-webkit-scrollbar-track,
  .syntax-highlighter::-webkit-scrollbar-track {
    background: #252526;
  }
