/* Main container for the Dashboard */
.dashboard-container {
  display: flex;
  height: 100vh;
}

/* Sidebar styling */
.sidebar {
  width: 250px; /* Adjust based on your sidebar design */
  background-color: var(--bg-tertiary);
  color: var(--text-primary);
}

/* Content section */
.dashboard-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);
}

/* Outer grid for cards */
.outer-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}

/* Outer cards styling */
.outer-card {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Inner grid for charts inside each card */
.inner-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 20px;
}

/* Inner cards styling */
.inner-card {
  background-color: var(--bg-quaternary);
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
