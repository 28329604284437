/* Overall Table Container */
.data-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px auto;
  font-family: "Poppins", sans-serif;
  background: var(--bg-secondary);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.15);
  animation: tableFadeIn 1s ease-in-out;
}

/* Table Header */
.data-table th {
  background: var(--accent-secondary);
  color: var(--text-primary);
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.05em;
}

.data-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Table Rows */
.data-table tbody tr {
  transition: background 0.3s ease, transform 0.2s ease;
}

.data-table tbody tr:nth-child(even) {
  background-color: var(--bg-tertiary);
}

.data-table tbody tr:nth-child(odd) {
  background-color: var(--bg-quaternary);
}

.data-table tbody tr:hover {
  background: linear-gradient(
    90deg,
    rgba(59, 87, 227, 0.15),
    rgba(169, 112, 255, 0.15)
  );
  transform: scale(1.02);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

/* Table Cells */
.data-table td {
  padding: 12px 15px;
  font-size: 15px;
  color: var(--text-secondary);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Last Row Adjustment */
.data-table tbody tr:last-child td {
  border-bottom: none;
}

/* Rounded Corners for the First and Last Cells */
.data-table tbody tr:first-child td:first-child {
  border-top-left-radius: 15px;
}

.data-table tbody tr:first-child td:last-child {
  border-top-right-radius: 15px;
}

.data-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}

.data-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}

/* Glow Effect on Hover */
.data-table tbody tr:hover td {
  box-shadow: inset 0 0 10px rgba(169, 112, 255, 0.25);
}

/* Fade-In Animation */
@keyframes tableFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .data-table th,
  .data-table td {
    font-size: 14px;
    padding: 10px;
  }
}
